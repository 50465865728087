const GSM_CHARACTERS = "@ 0Pp_!1AQaq$\"2BRbr#3CScs4DTdt%5EUeu&6FVfv'7GWgw(8HXhx)9IYiy\n*:JZjz+;Kk,<Ll\r-=Mm.>Nn/?Oo"
const EXTENDED_GSM_CHARACTERS = "|^€{}[]~\\"

const isCharacterValidGSM = (character: string) => {
  if (!character) return false
  for (let i = 0; i < GSM_CHARACTERS.length; i++)
      if (character === GSM_CHARACTERS[i]) return true
  for (let i = 0; i < EXTENDED_GSM_CHARACTERS.length; i++)
      if (character === EXTENDED_GSM_CHARACTERS[i]) return true
  return false
}

const isCharacterExtendedGSM = (character: string) => {
  if (!character) return false
  for (let i = 0; i < EXTENDED_GSM_CHARACTERS.length; i++)
      if (character === EXTENDED_GSM_CHARACTERS[i]) return true
  return false
}

const isValidGSM = (message: string) => {
  for (let i = 0; i < message.length; i++)
    if (!isCharacterValidGSM(message[i]))
      return false
  return true
}

const estimatePartsGSM = (message: string) => {
  let partsCount = 1;
  let charCount = 0;
  for (let i = 0; i < message.length; i++){
      if (partsCount === 1 && charCount === 160) {
          partsCount++;
          charCount = 7;
      } else if (partsCount > 1 && charCount === 153) {
          partsCount++;
          charCount = 0;
      }
      if (isCharacterExtendedGSM(message[i])) {
          if (partsCount === 1 && charCount === 159) {
              partsCount++;
              charCount = 7;
          } else if (partsCount > 1 && charCount === 152) {
              partsCount++;
              charCount = 0;
          }
          charCount += 2;
      } else {
          charCount++;
      }
  }
  return [partsCount, charCount];
}

const estimatePartsUnicode = (message: string) => {
  let partsCount = 1;
  let charCount = 0;
  for (let i = 0; i < message.length; i++){
      if (partsCount === 1 && charCount === 70) {
          partsCount++;
          charCount = 3;
      } else if (partsCount > 1 && charCount === 67) {
          partsCount++;
          charCount = 0;
      }
      charCount++;
  }
  return [partsCount, charCount];
}

export interface MessageInfo {
  isGsm?: boolean
  messageParts?: number
  characterCount?: number
  maxLength?: number
}

type GetMessageInfo = (message: string) => MessageInfo

export const getMessageInfo: GetMessageInfo = (message) => {
  const isGsm = isValidGSM(message)
  const [messageParts, characterCount] = isGsm
  ? estimatePartsGSM(message)
  : estimatePartsUnicode(message)
  return {
    isGsm,
    messageParts,
    characterCount,
    maxLength: isGsm
    ? messageParts === 1
    ? 160
    : 153
    : messageParts === 1
    ? 70
    : 67
  }
}
