import React, { useRef, useState } from 'react'
import BinaryDropdown from '../../components/form/BinaryDropdown';
import Button from '../../components/form/Button';
import DeleteButton from '../../components/form/DeleteButton';
import Input from '../../components/form/Input';
import { useAppSelector } from '../../hooks';
import Contact from '../../types/Contact';

interface Props {
  initialContact: Contact
  onDone: (updatedContact: Contact) => void
  onCancel: () => void
  onDelete: () => void
}

const EditContact = ({
  initialContact,
  onDone,
  onCancel,
  onDelete,
}: Props) => {

  const { apiKey } = useAppSelector(state => state.auth)

  const phoneNumberInputRef = useRef<HTMLInputElement | null>(null)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const [name, setName] = useState(initialContact.name || '')
  const [phoneNumber, setPhoneNumber] = useState(initialContact.phoneNumber)
  const [isSubscribed, setIsSubscribed] = useState(initialContact.isSubscribed)

  return (
    <div className="z-20 bg-black bg-opacity-40 fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center">
      <div className="relative w-11/12 sm:w-[34rem] max-h-[94%] drop-shadow-md p-6 bg-white rounded flex flex-col items-center">
        <h1 className='text-3xl mb-5 text-center'>Edit Contact</h1>
        <div className='w-full flex flex-col items-center overflow-visible'>
          {initialContact.isInCrm ? (
            <div className='text-center mb-3'>
              If you want to change any additional info, you can&nbsp;
              <a
                rel='noreferrer'
                target="_blank"
                href={`https://churchcrm.gospelga.com/PersonEditor.php?PersonID=${initialContact.id}`}
                className='text-blue-600 underline hover:opacity-80 active:opacity-70'
              >
                edit this person in the CRM
              </a>.
            </div>
          ) : (
            <>
              <Input
                label='Name (optional)'
                value={name}
                setValue={setName}
                onKeyDown={e => {
                  if (e.key === 'Enter') phoneNumberInputRef.current?.focus()
                }}
                addClassName='mb-3'
                disabled={loading}
              />
              <Input
                ref={phoneNumberInputRef}
                label='Phone Number'
                value={phoneNumber}
                setValue={setPhoneNumber}
                addClassName='mb-3'
                disabled={loading}
              />
            </>
          )}
          <BinaryDropdown
            label="Subscribed to announcements"
            value={isSubscribed}
            setValue={setIsSubscribed}
            disabled={loading}
            addClassName='w-full mb-2'
            includeNull={true}
            labelNull='Unknown'
          />
          {error && (
            <div className='w-full text-center text-lg text-red-600'>
              {error}
            </div>
          )}
          <Button
            addClassName="w-full mt-3"
            disabled={loading}
            onClick={async () => {
              const cleanName = name.trim()
              const cleanPhoneNumber = phoneNumber.trim()
              if (
                cleanName === initialContact.name
                && cleanPhoneNumber === initialContact.phoneNumber
                && isSubscribed === initialContact.isSubscribed
              ) return setError('Please update at least one field')
              if (!cleanPhoneNumber) {
                return setError('Please enter a phone number');
              }
              try {
                setLoading(true)
                if (error) setError('');
                const res = await fetch(
                  `https://announcements.gospelga.com/api/${initialContact.isInCrm ? 'crm-' : ''}contact/${initialContact.id}`,
                  {
                    method: 'POST',
                    headers: { 
                      'x-api-key': apiKey,
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      ...(cleanName !== initialContact.name && { name: cleanName }),
                      ...(!initialContact.isInCrm && { number: cleanPhoneNumber }),
                      isSubscribed,
                    })
                  }
                )
                if (!res.ok) {
                  let data: any = {};
                  try {
                    data = await res.json()
                  } catch(e) {
                    throw new Error('An unknown error occured.')
                  }
                  if (data.error) throw new Error(data.error);
                  else throw new Error('An unknown error occured.')
                }
                const data = await res.json()
                onDone(data)
              } catch(e: any) {
                setError(e.message)
                setLoading(false)
              }
            }}
          >
            Update contact
          </Button>
          {!initialContact.isInCrm && (
            <DeleteButton
              onClick={async () => {
                try {
                  setLoading(true)
                  const res = await fetch(
                    `https://announcements.gospelga.com/api/contact/${initialContact.id}`,
                    {
                      method: 'DELETE',
                      headers: { 'x-api-key': apiKey },
                    }
                  )
                  if (!res.ok) {
                    let data: any = {}
                    try {
                      data = await res.json()
                    } catch(e) {
                      throw new Error('An unknown error occured.')
                    }
                    if (data.error) throw new Error(data.error)
                    else throw new Error('An unknown error occured.')
                  }
                  onDelete()
                } catch(e: any) {
                  setError(e.message)
                  setLoading(false)
                }
              }}
              addClassName='w-full mt-3'
              label="Delete"
              disabled={loading}
            />
          )}
          <Button
            addClassName="w-full mt-3 bg-red-600"
            onClick={onCancel}
            disabled={loading}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default EditContact
