import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as DownArrowIcon } from '../../icons/down-arrow.svg'

export interface OptionMap {
  name: string
  value: string
}

interface Props {
  options: Array<OptionMap>
  value?: string
  setValue: (value: string) => void
  label?: string
  addClassName?: string
  disabled?: boolean
}

const Dropdown = ({
  options,
  value,
  setValue,
  label,
  disabled,
  addClassName,
}: Props) => {
  const [optionsMaxHeight, setOptionsMaxHeight] = useState(0)
  const optionsRef = useRef<HTMLDivElement | null>(null)
  const [showOptions, setShowOptions] = useState(false)
  
  const isMouseOverDropdown = useRef(false)

  useEffect(() => {
    const calcFilteredOptionsMaxHeight = () => {
      if (optionsRef.current) {
        const { top } = optionsRef.current.getBoundingClientRect()
        setOptionsMaxHeight(window.innerHeight - top - 8)
      }
    }
    calcFilteredOptionsMaxHeight()
    window.addEventListener('resize', calcFilteredOptionsMaxHeight)
    return () => window.removeEventListener('resize', calcFilteredOptionsMaxHeight)
  }, [showOptions])

  useEffect(() => {
    if (showOptions) {
      const onMouseDown = () => {
        if (!isMouseOverDropdown.current) setShowOptions(false)
      }
      window.addEventListener('mousedown', onMouseDown)
      return () => window.removeEventListener('mousedown', onMouseDown)
    }
  }, [showOptions])

  return (
    <div className={'flex flex-col text-left' + (addClassName ? ' ' + addClassName : '')}>
      {label && <label className='mb-1'>{label}</label>}
      <div
        className="relative flex flex-col"
        onMouseEnter={() => isMouseOverDropdown.current = true}
        onMouseLeave={() => isMouseOverDropdown.current = false}
      >
        <button
          className={
            'relative flex w-full h-10 py-2 pl-3 pr-10 text-left cursor-pointer border'
            + (disabled ? " bg-gray-100" : "")
            + (showOptions ? ' border-black border-b-gray-200 drop-shadow-md rounded-t' : ' border-gray-300 rounded')
          }
          disabled={disabled}
          onClick={() => setShowOptions(!showOptions)}
        >
          <div>{options.find(option => option.value === value)?.name}</div>
          <DownArrowIcon
            className={
              'absolute right-3 top-3 w-4 h-4 cursor-pointer pointer-events-none'
              + (showOptions ? " rotate-180" : "")
            }
          />
        </button>
        {showOptions && !!options.length && (
          <div
            ref={optionsRef}
            className='absolute z-10 w-full top-10 flex flex-col items-center text-center bg-white drop-shadow-md border-black border-l border-r border-b rounded-b overflow-y-auto dropdown-scrollbar'
            style={{ maxHeight: `${optionsMaxHeight}px` }}
          >
            {options.map((option, index) => (
              <div
                key={index}
                className={
                  'w-full p-2 hover:bg-gray-100 cursor-pointer'
                  + (index !== (options.length - 1) ? ' border-b border-b-gray-200' : ' rounded-b')
                }
                onClick={() => {
                  setValue(option.value)
                  setShowOptions(false)
                }}
              >
                {option.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Dropdown
