import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import Contact from '../../types/Contact'
import { ReactComponent as TimesIcon } from '../../icons/times.svg'
import { ReactComponent as DownArrowIcon } from '../../icons/down-arrow.svg'
import { setContacts, setContactsLoading } from '../../slices/contacts'
import formatNumber from '../../util/formatNumber'
import ConfirmButton from '../../components/form/ConfirmButton'
import parseNumber from '../../util/parseNumber'

interface Props {
  selectedContacts: Contact[]
  setSelectedContacts: (selectedContacts: Contact[]) => void
  label?: string
  addClassName?: string
  disabled?: boolean
}

const ContactSelector = ({
  selectedContacts,
  setSelectedContacts,
  label,
  disabled,
  addClassName,
}: Props) => {
  const dispatch = useAppDispatch()

  const apiKey = useAppSelector(state => state.auth.apiKey)

  const {
    initialLoaded,
    loading: contactsLoading,
    contacts,
  } = useAppSelector(state => state.contacts)

  const [unselectedContacts, setUnselectedContacts] = useState(
    selectedContacts.length
    ? contacts.filter(contact => (
      !selectedContacts.find(({ id, isInCrm }) => (
        contact.id === id && contact.isInCrm === isInCrm
      ))
    ))
    : contacts
  )
  const [filteredContacts, setFilteredContacts] = useState(unselectedContacts)
  
  const dropdownOptionsRef = useRef<HTMLDivElement | null>(null)
  const [maxDropdownHeight, setMaxDropdownHeight] = useState(0)
  
  const mouseIsOverDropdown = useRef(false)
  
  const [showOptions, setShowOptions] = useState(false)
  const [inputValue, setInputValue] = useState(initialLoaded ? '' : 'Loading contacts...')
  
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const onEnter = useCallback(() => {
    setShowOptions(true)
  }, [])

  const onExit = useCallback(() => {
    setShowOptions(false)
  }, [])

  const openImportDialog = useCallback(() => {
    fileInputRef.current?.click()
  }, [])

  const processCsv = useCallback((csvString: string) => {
    const rows = csvString.split('\n')
    if (!rows.length) return

    const parsedNumbers: string[] = []

    rows.forEach((row) => {
      const cells = row.split(',')
      cells.forEach((cell) => {
        const parsedNumber = parseNumber(cell)
        if (parsedNumber) {
          parsedNumbers.push(parsedNumber)
        }
      })
    })

    const parsedContacts: Contact[] = []

    parsedNumbers.forEach((phoneNumber) => {
      const contact = contacts.find(contact => (
        contact.phoneNumber === phoneNumber
      ))
      if (contact) parsedContacts.push(contact)
    })

    if (parsedContacts.length) setSelectedContacts(parsedContacts)
  }, [contacts, setSelectedContacts])

  const importRecipients = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader()
    reader.onload = e => {
      if (e.target && typeof e.target.result === 'string') {
        processCsv(e.target.result)
      }
    }
    if (e.target.files?.length) {
      reader.readAsText(e.target.files[0])
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }, [processCsv])

  useEffect(() => {
    if (selectedContacts.length) {
      setUnselectedContacts(contacts.filter(contact => (
        !selectedContacts.find(({ id, isInCrm }) => (
          contact.id === id && contact.isInCrm === isInCrm
        ))
      )))
    } else {
      setUnselectedContacts(contacts)
    }
  }, [selectedContacts, contacts])

  useEffect(() => {
    if (!inputValue) {
      setFilteredContacts(unselectedContacts)
    } else {
      const trimmedSearch = inputValue.trim()
      const phoneSearch = trimmedSearch.replace(/[^0-9]+/g, '')
      const nameSearch = trimmedSearch.replace(/[^a-zA-Z\s]+/g, '').trim().replace(/\s+/g, ' ').toLowerCase()
      setFilteredContacts(
        unselectedContacts.filter(contact => {
          if (trimmedSearch) {
            if (phoneSearch && !contact.phoneNumber.includes(phoneSearch)) return false
            if (nameSearch) {
              if (!contact.name) return false
              const lowercaseName = contact.name.toLowerCase()
              if (nameSearch.includes(' ')) {
                const searchParts = nameSearch.split(' ')
                const nameParts = lowercaseName.split(' ')
                for (const searchPart of searchParts) {
                  let searchPartFound = false
                  for (const namePart of nameParts)
                    if (namePart.includes(searchPart)) searchPartFound = true
                  if (!searchPartFound) return false
                }
              } else {
                if (!lowercaseName.includes(nameSearch)) return false
              }
            }
          }
          return true
        })
      )
    }
  }, [inputValue, unselectedContacts])

  useEffect(() => {
    const calcFilteredContactsMaxHeight = () => {
      if (dropdownOptionsRef.current) {
        const { top } = dropdownOptionsRef.current.getBoundingClientRect()
        setMaxDropdownHeight(window.innerHeight - top - 8)
      }
    }
    calcFilteredContactsMaxHeight()
    window.addEventListener('resize', calcFilteredContactsMaxHeight)
    document.querySelector('.content')?.addEventListener('scroll', calcFilteredContactsMaxHeight)
    return () => {
      window.removeEventListener('resize', calcFilteredContactsMaxHeight)
      document.querySelector('.content')?.addEventListener('scroll', calcFilteredContactsMaxHeight)
    }
  }, [filteredContacts, showOptions])

  useEffect(() => {
    if (!initialLoaded) {
      const fetchContacts = async () => {
        try {
          dispatch(setContactsLoading(true))
    
          const res = await fetch('https://announcements.gospelga.com/api/contacts', {
            method: 'POST',
            headers: { 'x-api-key': apiKey },
          })
    
          if (!res.ok) throw new Error('Failed to get contacts.');
    
          const fetchedContacts = (await res.json() as Contact[]).sort(
            (a, b) => (
              (a.name && b.name)
              ? a.name.localeCompare(b.name)
              : a.name && !b.name
              ? -1
              : !a.name && b.name
              ? 1
              : a.phoneNumber.localeCompare(b.phoneNumber)
            )
          )
    
          dispatch(setContacts(fetchedContacts))
          dispatch(setContactsLoading(false))
          setInputValue('')
        } catch(e: any) {
          setInputValue(e.message ? `Error: ${e.message}` : 'An unknown error occured.')
        }
      }
      fetchContacts()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={'w-full flex flex-col' + (addClassName ? ' ' + addClassName : '')}>
      <div className="mb-1.5 flex justify-between items-end">
        <label>{label}</label>
        <div className="flex items-center">
          <ConfirmButton
            addClassName='text-xs ml-1.5 p-1.5'
            confirmTitle="Override selected recipients?"
            confirmMessage="Recipients that are currently selected will be removed."
            onClick={openImportDialog}
            skipConfirmation={!selectedContacts.length}
            disabled={disabled || contactsLoading}
          >
            Import recipients from CSV
          </ConfirmButton>
          <ConfirmButton
            addClassName='text-xs ml-1.5 p-1.5'
            confirmTitle="Clear recipients?"
            confirmMessage="Recipients that are currently selected will be removed."
            onClick={() => setSelectedContacts([])}
            disabled={disabled || contactsLoading || !selectedContacts.length}
          >
            Clear recipients
          </ConfirmButton>
          <input
            ref={fileInputRef}
            type="file"
            accept='.csv'
            className='hidden'
            onChange={importRecipients}
            disabled={disabled || contactsLoading}
          />
        </div>
      </div>
      {!!selectedContacts.length && (
        <div className="flex flex-wrap mb-1">
          {selectedContacts.map(contact => (
            <div
              key={`${contact.isInCrm ? 'crm' : 'contact'}-${contact.id}`}
              className={
                "flex items-center pt-1 pb-[0.2625rem] pl-2.5 pr-1 mr-1.5 mb-2 rounded-full"
                + (disabled ? ' bg-gray-200 text-gray-700' : ' bg-blue-200 text-blue-700')
              }
            >
              <div className='mr-0.5'>{contact.name || formatNumber(contact.phoneNumber)}</div>
              <button
                className={
                  'w-6 h-6 rounded-full flex justify-center items-center'
                  + (disabled ? ' opacity-60' : ' hover:opacity-80 active:opacity-70 cursor-pointer')
                }
                onClick={() => setSelectedContacts(
                  selectedContacts.filter(({ id, isInCrm }) => (
                    !(contact.id === id && contact.isInCrm === isInCrm)
                  ))
                )}
                disabled={disabled || contactsLoading}
              >
                <TimesIcon className='w-3 h-3' />
              </button>
            </div>
          ))}
        </div>
      )}
      <div
        className="relative w-full flex flex-col"
        onMouseEnter={() => mouseIsOverDropdown.current = true}
        onMouseLeave={() => mouseIsOverDropdown.current = false}
      >
        <input
          type="text"
          className={
            'w-full h-10 p-2 outline-none border'
            + ((disabled || contactsLoading) ? " cursor-default bg-gray-100 opacity-70" : " cursor-text") + (
              showOptions
              ? ' border-black border-b-gray-200 drop-shadow-md rounded-t'
              : ' border-gray-300 rounded'
            )
          }
          value={inputValue}
          disabled={(disabled || contactsLoading)}
          onFocus={onEnter}
          onBlur={() => { if (!mouseIsOverDropdown.current) onExit() }}
          onChange={e => {
            const newValue = e.target.value
            setInputValue(newValue)
          }}
        />
        {showOptions && (
          <div
            ref={dropdownOptionsRef}
            className='absolute w-full z-20 top-10 flex flex-col items-center text-center bg-white drop-shadow-md rounded-b border-l border-r border-b border-black overflow-y-auto dropdown-scrollbar'
            style={{ maxHeight: `${maxDropdownHeight}px` }}
          >
            {filteredContacts.length ? filteredContacts.map((contact, index) => (
              <div
                key={`${contact.isInCrm ? 'crm' : 'contact'}-${contact.id}`}
                className={
                  'w-full p-2 hover:bg-gray-100 cursor-pointer border-b'
                  + (index !== (filteredContacts.length - 1) ? ' border-b border-b-gray-200' : ' rounded-b')
                }
                onClick={() => {
                  setSelectedContacts([...selectedContacts, contact])
                  setInputValue('')
                  onExit()
                }}
              >
                <div className="text-sm text-gray-500">{contact.name || 'Unknown'}</div>
                <div>{formatNumber(contact.phoneNumber)}</div>
              </div>
            )) : (
              <div className='w-full p-2 rounded-b'>No results</div>
            )}
          </div>
        )}
        <button
          className={
            'absolute top-0 right-0 bottom-0 w-10 cursor-pointer'
            + (showOptions ? "" : "")
          }
          onClick={() => {
            if (showOptions) onExit()
            else onEnter()
          }}
          onBlur={() => { if (!mouseIsOverDropdown.current) onExit() }}
          disabled={disabled || contactsLoading}
        >
          <DownArrowIcon
            className={
              'absolute right-3 top-3 w-4 h-4 cursor-pointer pointer-events-none'
              + (showOptions ? " rotate-180" : "")
              + (disabled || contactsLoading ? ' opacity-40' : '')
            }
          />
        </button>
      </div>
    </div>
  )
}

export default ContactSelector
