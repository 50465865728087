import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../components/form/Button'
import Checkbox from '../components/form/Checkbox'
import Input from '../components/form/Input'
import { useAppDispatch } from '../hooks'
import { setApiKey } from '../slices/auth'

const SignIn = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [shouldRemember, setShouldRemember] = useState(false)

  const attemptToSignIn = () => {
    if (!username) return setErrorMessage('Please enter a username.')
    if (!password) return setErrorMessage('Please enter a password.')
    setErrorMessage('')
    setLoading(true)
    axios.post(
      'https://announcements.gospelga.com/api/login',
      { username, password }
    ).then(res => {
      const apiKey = res.data.apiKey
      if (shouldRemember) {
        try {
          localStorage.setItem('apiKey', apiKey)
        } finally {}
      }
      dispatch(setApiKey(apiKey))
      navigate('/', { replace: true })
    }).catch(e => {
      if (e.response.status === 401) {
        setErrorMessage("Incorrect username or password.")
      } else {
        setErrorMessage("Unknown error.")
      }
      setLoading(false)
    })
  }

  return (
    <div className='w-full h-full bg-gray-300 flex justify-center items-center'>
      <div
        className='card w-[20rem] bg-white drop-shadow-md rounded p-6 flex flex-col justify-center items-center text-center'
      >
        <Input
          label="Username"
          value={username}
          setValue={setUsername}
          disabled={loading}
          addClassName="mb-3"
        />
        <Input
          type='password'
          label="Password"
          value={password}
          setValue={setPassword}
          disabled={loading}
          addClassName="mb-2"
          onKeyDown={e => e.key === "Enter" && attemptToSignIn()}
        />
        <Checkbox
          id="remember-me-checkbox"
          label="Remember me"
          value={shouldRemember}
          setValue={setShouldRemember}
          addClassName="mb-2"
          disabled={loading}
        />
        {errorMessage && <div className='text-red-600'>{errorMessage}</div>}
        <Button
          addClassName={"w-full" + (errorMessage ? " mt-2" : "")}
          disabled={loading}
          onClick={attemptToSignIn}
        >
          Sign in
        </Button>
      </div>
    </div>
  )
}

export default SignIn
