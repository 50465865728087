import React from 'react'
import { Outlet } from 'react-router-dom'

const Content = () => {
  return (
    <div className='content w-full h-full p-4 flex flex-col items-center overflow-auto'>
      <Outlet />
    </div>
  )
}

export default Content