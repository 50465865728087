import React, { useEffect, useState } from 'react'
import Dropdown, { OptionMap } from './Dropdown'

interface Props {
  value: boolean | null
  setValue: (value: boolean | null) => void
  includeNull?: boolean
  labelTrue?: string
  labelFalse?: string
  labelNull?: string
  label?: string
  addClassName?: string
  disabled?: boolean
}

const BinaryDropdown = ({
  value,
  setValue,
  includeNull = false,
  label,
  labelTrue = 'Yes',
  labelFalse = 'No',
  labelNull = 'None',
  addClassName,
  disabled,
}: Props) => {

  const [options, setOptions] = useState<OptionMap[]>([])

  useEffect(() => {
    const newOptions: OptionMap[] = [
      { name: labelTrue, value: 'true' },
      { name: labelFalse, value: 'false' },
    ]

    if (includeNull) newOptions.push({
      name: labelNull,
      value: 'null',
    })

    setOptions(newOptions)
  }, [includeNull, labelTrue, labelFalse, labelNull])

  return (
    <Dropdown
      label={label}
      options={options}
      value={String(value)}
      setValue={value => setValue(value === 'null' ? null : value === 'true')}
      addClassName={addClassName}
      disabled={disabled}
    />
  )
}

export default BinaryDropdown
