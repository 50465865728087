import React from 'react'
import { ReactComponent as LoadingIcon } from '../icons/loading.svg'

interface Props {
  addClassName?: string
}

const Loading = ({
  addClassName
} : Props) => {
  return <LoadingIcon className={'animate-spin ' + (addClassName || '')} />
}

export default Loading