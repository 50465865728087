import { useRef } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from '../store'

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useThrottle = (callback: Function, timeout: number) => {
  const throttling = useRef(false)
  const pendingArgs = useRef<any>(null)

  const timeoutFunc = () => {
    if (pendingArgs.current === null) {
      throttling.current = false
    } else {
      callback(...pendingArgs.current)
      pendingArgs.current = null
      setTimeout(timeoutFunc, timeout)
    }
  }

  return (...args: any) => {
    if (throttling.current) {
      pendingArgs.current = args
      return
    }
    throttling.current = true
    callback(...args)
    setTimeout(timeoutFunc, timeout)
  }
}
