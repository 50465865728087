import React from 'react'
import EditButton from '../../components/form/EditButton'
// import MessageButton from '../../components/form/MessageButton'
import Contact from '../../types/Contact'
import formatNumber from '../../util/formatNumber'

interface Props {
  contact: Contact
  setContactBeingEdited: (contact: Contact) => void
}

const ContactItem = ({
  contact,
  setContactBeingEdited,
}: Props) => {
  return (
    <div className='w-full p-4 mb-3 border border-black rounded flex justify-between items-center'>
      <div className="flex flex-col">
        <div className="flex flex-wrap items-center text-gray-500">
          {contact.name ? (
            <>
              {contact.name.split(' ').map((part, index) => (
                <span key={index} className='mr-1'>{part}</span>
              ))}
            </>
          ) : (
            <span className='mr-1'>Unknown</span>
          )}
          {contact.isInCrm && (
            <span className='text-xs rounded-full bg-gray-400 text-white py-1 px-1.5'>
              CRM
            </span>
          )}
        </div>
        <div className="text-xl">{formatNumber(contact.phoneNumber)}</div>
      </div>
      <div className='flex items-center'>
        {/* <MessageButton
          addClassName='mr-2 w-11 h-11 sm:w-fit sm:h-fit'
          addLabelClassName='hidden sm:inline'
        /> */}
        <EditButton
          // addClassName='w-11 h-11 sm:w-fit sm:h-fit'
          // addLabelClassName='hidden sm:inline'
          onClick={() => setContactBeingEdited(contact)}
        />
      </div>
    </div>
  )
}

export default ContactItem