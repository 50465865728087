import React from 'react'
import { ReactComponent as DeleteIcon } from '../../icons/trash.svg'
import ConfirmButton, { Props as ConfirmButtonProps } from './ConfirmButton'

interface Props extends ConfirmButtonProps {
  label?: string
  addLabelClassName?: string
}

const DeleteButton = ({
  label = "Delete",
  addLabelClassName,
  addClassName,
  ...props
}: Props) => {
  return (
    <ConfirmButton
      addClassName={"bg-red-600 text-white" + (addClassName ? " " + addClassName : "")}
      {...props}
    >
      <DeleteIcon className='w-5 h-5'/>
      <div
        className={'text-md ml-1' + (addLabelClassName ? ' ' + addLabelClassName : '')}
      >
        {label}
      </div>
    </ConfirmButton>
  )
}

export default DeleteButton