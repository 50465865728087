import { configureStore } from '@reduxjs/toolkit'
import auth from './slices/auth'
import menu from './slices/menu'
import announcements from './slices/announcements'
import contacts from './slices/contacts'

const store = configureStore({
  reducer: {
    auth,
    menu,
    announcements,
    contacts,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
