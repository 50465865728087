import React, { useState } from 'react'
import Button from './Button'

export interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children?: React.ReactNode
  disabled?: boolean
  addClassName?: string
  confirmTitle?: React.ReactNode
  confirmMessage?: React.ReactNode
  confirmButtonText?: string
  cancelButtonText?: string
  onClick: () => void
  skipConfirmation?: boolean
}

const ConfirmButton = ({
  children,
  disabled,
  addClassName,
  confirmTitle = "Are you sure?",
  confirmMessage,
  confirmButtonText = "Yes",
  cancelButtonText = "No",
  onClick,
  skipConfirmation = false,
  ...props
}: Props) => {

  const [isConfirming, setIsConfirming] = useState(false)

  return (
    <>
      <button
        className={
          "bg-black text-white p-2 rounded select-none flex justify-center items-center"
          + (disabled ? " bg-opacity-60" : " hover:bg-opacity-80 active:bg-opacity-70")
          + (addClassName ? " " + addClassName : "")
        }
        disabled={disabled}
        onClick={() => {
          if (skipConfirmation) onClick()
          else setIsConfirming(true)
        }}
        {...props}
      >
        {children}
      </button>
      {isConfirming && (
        <div className="z-20 bg-black bg-opacity-40 fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center">
          <div className="w-11/12 sm:w-[30rem] max-h-[94%] drop-shadow-md p-6 bg-white rounded flex flex-col items-center">
            {confirmTitle && <h1 className='text-3xl mb-5 text-center'>{confirmTitle}</h1>}
            {confirmMessage && (
              typeof confirmMessage === 'string'
              ? <div className='text-lg mb-5 text-center'>{confirmMessage}</div>
              : confirmMessage
            )}
            <div className="w-full flex">
              <Button
                addClassName="w-full"
                onClick={() => {
                  setIsConfirming(false);
                  onClick();
                }}
              >{confirmButtonText}</Button>
              <Button
                addClassName="w-full ml-2 bg-red-600"
                onClick={() => setIsConfirming(false)}
              >{cancelButtonText}</Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ConfirmButton
