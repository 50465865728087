const parseNumber = (phoneNumber: string) => {
  if (!phoneNumber) return null
  phoneNumber = phoneNumber.trim().replace(/[^0-9]+/g, '')
  if (!phoneNumber) return null
  if (phoneNumber.length === 10)
    phoneNumber = "1" + phoneNumber
  if (phoneNumber.length === 11 && phoneNumber[0] === "1") {
    return phoneNumber
  } else {
    return null
  }
}

export default parseNumber
