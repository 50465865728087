import React, { forwardRef } from 'react'

export interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children?: React.ReactNode
  disabled?: boolean
  addClassName?: string
}

const Button = forwardRef<HTMLButtonElement, Props>(({
  children,
  disabled,
  addClassName,
  ...props
}, ref) => {
  return (
    <button
      ref={ref}
      className={
        "bg-black text-white p-2 rounded select-none flex justify-center items-center"
        + (disabled ? " bg-opacity-60" : " hover:bg-opacity-80 active:bg-opacity-70")
        + (addClassName ? " " + addClassName : "")
      }
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
})

export default Button
