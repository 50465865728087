import { createSlice } from '@reduxjs/toolkit'

let apiKey = ''
try {
  const savedApiKey = localStorage.getItem('apiKey')
  if (savedApiKey) apiKey = savedApiKey
} finally {}

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: !!apiKey,
    apiKey,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setApiKey: (state, action) => {
      state.apiKey = action.payload
    },
    clearApiKey: (state) => {
      try {
        localStorage.removeItem('apiKey')
      } finally {
        state.apiKey = ''
        if (state.loading) state.loading = false
      }
    },
  },
})

export const { setLoading, setApiKey, clearApiKey } = authSlice.actions

export default authSlice.reducer
