import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Announcement from '../types/Announcement'
import Contact from '../types/Contact'

interface AnnouncementsState {
  initialLoaded: boolean
  loading: boolean
  sending: boolean
  currentAnnouncement: string
  announcements: Array<Announcement>
  announcementOffset: number
  moreAnnouncementsAvailable: boolean
  shouldSendToAll: boolean
  selectedContacts: Contact[]
}

const initialState: AnnouncementsState = {
  initialLoaded: false,
  loading: true,
  sending: false,
  currentAnnouncement: '',
  announcements: [],
  announcementOffset: 0,
  moreAnnouncementsAvailable: true,
  shouldSendToAll: true,
  selectedContacts: [],
}

export const announcementsSlice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {
    setCurrentAnnouncement: (state, action: PayloadAction<string>) => {
      state.currentAnnouncement = action.payload
    },
    setAnnouncementsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setSending: (state, action: PayloadAction<boolean>) => {
      state.sending = action.payload
    },
    setAnnouncements: (state, action: PayloadAction<Array<Announcement>>) => {
      state.announcements = action.payload
      if (!state.initialLoaded) state.initialLoaded = true
    },
    appendAnnouncements: (state, action: PayloadAction<Array<Announcement>>) => {
      state.announcements = [...state.announcements, ...action.payload]
      if (!state.initialLoaded) state.initialLoaded = true
    },
    prependAnnouncement: (state, action: PayloadAction<Announcement>) => {
      state.announcements = [action.payload, ...state.announcements]
    },
    setAnnouncementOffset: (state, action: PayloadAction<number>) => {
      state.announcementOffset = action.payload
    },
    setMoreAnnouncementsAvailable: (state, action: PayloadAction<boolean>) => {
      state.moreAnnouncementsAvailable = action.payload
    },
    setShouldSendToAll: (state, action: PayloadAction<boolean>) => {
      state.shouldSendToAll = action.payload
    },
    setSelectedContacts: (state, action: PayloadAction<Contact[]>) => {
      state.selectedContacts = action.payload
    },
  },
})

export const {
  setCurrentAnnouncement,
  setAnnouncements,
  setSending,
  appendAnnouncements,
  prependAnnouncement,
  setAnnouncementOffset,
  setMoreAnnouncementsAvailable,
  setAnnouncementsLoading,
  setShouldSendToAll,
  setSelectedContacts,
} = announcementsSlice.actions

export default announcementsSlice.reducer
