import React from 'react'
import Content from './Content'
import Header from './Header'
import Menu from './Menu'

const AppWrapper = () => {
  return (
    <div className='relative w-full h-full flex flex-col overflow-hidden'>
        <Header />
        <div className='relative w-full h-full overflow-hidden'>
            <Content />
            <Menu />
        </div>
    </div>
  )
}

export default AppWrapper