import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setContacts, setContactsLoading } from '../../slices/contacts'
import Contact from '../../types/Contact';
import formatNumber from '../../util/formatNumber';
import Loading from '../../components/Loading';
import Button from '../../components/form/Button';
import { ReactComponent as CloseIcon } from "../../icons/times-solid.svg";

interface Props {
  memberIds: number[];
  contactIds: number[];
  onExit: () => void;
}

const RecipientList = ({
  memberIds,
  contactIds,
  onExit,
}: Props) => {
  const dispatch = useAppDispatch()

  const apiKey = useAppSelector(state => state.auth.apiKey)

  const {
    initialLoaded,
    loading,
    contacts,
  } = useAppSelector(state => state.contacts)

  const [error, setError] = useState('')
  const [recipients, setRecipients] = useState(contacts.filter(contact => {
    if (contact.isInCrm) {
      return memberIds.includes(Number(contact.id))
    } else {
      return contactIds.includes(Number(contact.id))
    }
  }))

  useEffect(() => {
    setRecipients(contacts.filter(contact => {
      if (contact.isInCrm) {
        return memberIds.includes(Number(contact.id))
      } else {
        return contactIds.includes(Number(contact.id))
      }
    }))
  }, [contacts, contactIds, memberIds])

  useEffect(() => {
    if (!initialLoaded) {
      const fetchContacts = async () => {
        try {
          dispatch(setContactsLoading(true))
    
          const res = await fetch('https://announcements.gospelga.com/api/contacts', {
            method: 'POST',
            headers: { 'x-api-key': apiKey },
          })
    
          if (!res.ok) throw new Error('Failed to get contacts.');
    
          const fetchedContacts = (await res.json() as Contact[]).sort(
            (a, b) => (
              (a.name && b.name)
              ? a.name.localeCompare(b.name)
              : a.name && !b.name
              ? -1
              : !a.name && b.name
              ? 1
              : a.phoneNumber.localeCompare(b.phoneNumber)
            )
          )
    
          dispatch(setContacts(fetchedContacts))
          dispatch(setContactsLoading(false))
          setError('')
        } catch(e: any) {
          setError(e.message ? `Error: ${e.message}` : 'An unknown error occured.')
        }
      }
      fetchContacts()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="z-20 bg-black bg-opacity-40 fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center">
      <div className="w-11/12 sm:w-[30rem] max-h-[94%] drop-shadow-md py-6 px-3 bg-white rounded flex flex-col items-center">
        <h1 className='text-3xl mb-5 text-center'>Recipients</h1>
        <div className='w-full flex flex-col items-center px-3 overflow-y-auto'>
          {error && (
            <div className='w-full text-center text-lg text-red-600'>
              {error}
            </div>
          )}
          {loading && <Loading addClassName='w-8 h-8' />}
          {recipients.length ? recipients.map(contact => (
            <div
              key={`${contact.isInCrm ? 'crm' : 'contact'}-${contact.id}`}
              className='w-full p-2 mb-3 border rounded'
            >
              <div className='text-gray-500'>{contact.name || 'Unknown'}</div>
              <div className='text-lg'>{formatNumber(contact.phoneNumber)}</div>
            </div>
          )) : (
            <div className='text-lg text-center text-gray-500 italic'>None.</div>
          )}
        </div>
        <Button 
          className='absolute top-4 right-4 hover:opacity-80 active:opacity-70'
          onClick={onExit}
        >
          <CloseIcon className="w-6 h-6 text-gray-400"/>
        </Button>
      </div>
    </div>
  )
}

export default RecipientList