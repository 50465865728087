import React from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useAppSelector } from '../../hooks'

const RequireAuth = () => {
  const location = useLocation()

  const { loading, apiKey } = useAppSelector(state => state.auth)

  if (loading) return <p>Loading...</p>
  
  if (!apiKey)
    return <Navigate to="/signin" state={location.pathname} replace />

  return <Outlet />
}

export default RequireAuth
