import React from 'react'

interface Props {
  id: string
  value: boolean
  setValue(value: boolean): void
  label?: string
  addClassName?: string
  addInputClassName?: string
  addLabelClassName?: string
  disabled?: boolean
}

const Checkbox = ({
  id,
  value,
  setValue,
  label,
  addClassName,
  disabled
}: Props) => {
  return (
    <div className={"flex items-center" + (addClassName ? " " + addClassName : "")}>
      <input
        type="checkbox"
        id={id}
        className="w-3.5 h-3.5 bg-dark-green-800 text-white"
        checked={value}
        onChange={() => setValue(!value)}
        disabled={disabled}
      />
      <label
        className="ml-2 select-none"
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  )
}

export default Checkbox
