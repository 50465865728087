import React from 'react'
import { useAppDispatch } from '../../hooks'
import { closeMenu } from '../../slices/menu'

interface Props {
  title: string
  description?: string
  icon?: React.ReactNode
  onClick?: () => void
  closeMenuOnClick?: boolean
}

const MenuItem = ({
  title,
  description,
  icon,
  onClick,
  closeMenuOnClick = true,
}: Props) => {
  const dispatch = useAppDispatch()

  return (
    <button
      className='w-full h-24 p-4 my-2 flex justify-between align-center select-none border border-white hover:bg-white/10 active:bg-white/20 rounded'
      onClick={e => {
        e.stopPropagation()
        if (closeMenuOnClick) dispatch(closeMenu())
        if (onClick) onClick()
      }}
    >
      {icon && <div className='h-full aspect-square mr-4'>{icon}</div>}
      <div className='w-full h-full flex flex-col justify-around items-center'>
        <h2 className='text-xl font-bold'>{title}</h2>
        {description && <p>{description}</p>}
      </div>
    </button>
  )
}

export default MenuItem