import React, { useState } from 'react'
import AnnouncementT from '../../types/Announcement'
import RecipientList from './RecipientList'

interface Props {
  announcement: AnnouncementT
}

const Announcement = ({
  announcement
}: Props) => {
  const [showRecipients, setShowRecipients] = useState(false)

  return (
    <div className='w-full p-4 my-3 flex flex-col rounded bg-gray-200 whitespace-pre-wrap'>
      <div className='text-lg mb-2'>{announcement.text}</div>
      <div className="w-full flex justify-between">
        <div className='text-sm'>{new Date(announcement.timestamp).toLocaleString()}</div>
        <div
          className='text-sm text-gray-500 italic hover:underline hover:cursor-pointer'
          onClick={() => setShowRecipients(true)}
        >
          {announcement.recipientCount} recipient{announcement.recipientCount === 1 ? '' : 's'}
        </div>
      </div>
      {showRecipients && (
        <RecipientList
          memberIds={announcement.toMembers}
          contactIds={announcement.toContacts}
          onExit={() => setShowRecipients(false)}
        />)}
    </div>
  )
}

export default Announcement