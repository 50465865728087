import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Contact from '../types/Contact'

interface ContactsState {
  initialLoaded: boolean
  loading: boolean
  contacts: Array<Contact>
  subscribedContactsAmount: number
}

const initialState: ContactsState = {
  initialLoaded: false,
  loading: true,
  contacts: [],
  subscribedContactsAmount: 0,
}

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setContactsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setContacts: (state, action: PayloadAction<Array<Contact>>) => {
      state.contacts = action.payload
      if (!state.initialLoaded) state.initialLoaded = true
      state.subscribedContactsAmount = state.contacts.reduce(
        (sum, contact) => contact.isSubscribed ? sum + 1 : sum, 0
      )
    },
    appendContacts: (state, action: PayloadAction<Array<Contact>>) => {
      state.contacts = [...state.contacts, ...action.payload]
      if (!state.initialLoaded) state.initialLoaded = true
      state.subscribedContactsAmount = state.contacts.reduce(
        (sum, contact) => contact.isSubscribed ? sum + 1 : sum, 0
      )
    },
    addContact: (state, action: PayloadAction<Contact>) => {
      state.contacts = [action.payload, ...state.contacts].sort((a, b) => (
        (a.name && b.name)
        ? a.name.localeCompare(b.name)
        : a.name && !b.name
        ? -1
        : !a.name && b.name
        ? 1
        : a.phoneNumber.localeCompare(b.phoneNumber)
      ))
      state.subscribedContactsAmount = state.contacts.reduce(
        (sum, contact) => contact.isSubscribed ? sum + 1 : sum, 0
      )
    },
    updateContact: (state, action: PayloadAction<Contact>) => {
      state.contacts = state.contacts.map(contact => {
        if (
          (contact.id === action.payload.id)
          && (contact.isInCrm === action.payload.isInCrm)
        ) return action.payload
        return contact
      }).sort((a, b) => (
        (a.name && b.name)
        ? a.name.localeCompare(b.name)
        : a.name && !b.name
        ? -1
        : !a.name && b.name
        ? 1
        : a.phoneNumber.localeCompare(b.phoneNumber)
      ))
      state.subscribedContactsAmount = state.contacts.reduce(
        (sum, contact) => contact.isSubscribed ? sum + 1 : sum, 0
      )
    },
    removeContact: (state, action: PayloadAction<Contact>) => {
      state.contacts = state.contacts.filter(contact => (
        !((contact.id === action.payload.id) && (contact.isInCrm === action.payload.isInCrm))
      ))
      state.subscribedContactsAmount = state.contacts.reduce(
        (sum, contact) => contact.isSubscribed ? sum + 1 : sum, 0
      )
    },
  },
})

export const {
  setContacts,
  appendContacts,
  addContact,
  updateContact,
  removeContact,
  setContactsLoading,
} = contactsSlice.actions

export default contactsSlice.reducer
