import React, { forwardRef } from 'react'
import { ReactComponent as EditIcon } from '../../icons/pencil.svg'
import Button, { Props as ButtonProps } from './Button'

interface Props extends ButtonProps {
  label?: string
  addLabelClassName?: string
}

const EditButton = forwardRef<HTMLButtonElement, Props>(({
  label = "Edit",
  addLabelClassName,
  addClassName,
  ...props
}, ref) => {
  return (
    <Button
      {...props}
      addClassName={"bg-blue-600 text-white" + (addClassName ? " " + addClassName : "")}
      ref={ref}
    >
      <EditIcon className='w-5 h-5'/>
      <div
        className={'text-md ml-2' + (addLabelClassName ? ' ' + addLabelClassName : '')}
      >
        {label}
      </div>
    </Button>
  )
})

export default EditButton