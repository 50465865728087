import axios from 'axios'
import React, { useEffect } from 'react'
import { MemoryRouter as Router, Routes, Route } from 'react-router-dom'
import RequireAuth from './components/auth/RequireAuth'
import AppWrapper from './components/layout/AppWrapper'
import { useAppDispatch, useAppSelector } from './hooks'
import Announcements from './pages/Announcements'
import Contacts from './pages/Contacts'
// import Messages from './pages/Messages'
import SignIn from './pages/SignIn'
import { clearApiKey, setLoading as setAuthLoading } from './slices/auth'

const App = () => {
  const dispatch = useAppDispatch()
  const apiKey = useAppSelector(state => state.auth.apiKey)

  useEffect(() => {
    if (apiKey) {
      axios.post(
        'https://announcements.gospelga.com/api/verify',
        { apiKey }
      ).then(() => {
        dispatch(setAuthLoading(false))
      }).catch(() => {
        dispatch(clearApiKey())
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Router>
      <Routes>
        <Route path='/signin' element={<SignIn />} />
        <Route element={<RequireAuth />}>
          <Route element={<AppWrapper />}>
            <Route path='/' element={<Announcements />} />
            <Route path='/contacts' element={<Contacts />} />
            {/* <Route path='/messages' element={<Messages />} /> */}
          </Route>
        </Route>
      </Routes>
    </Router>
  )
}

export default App
