import React from 'react'
import { useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { toggleMenu } from '../../slices/menu'

const locationNames: { [key: string]: string } = {
  '/': 'Announcements',
  '/messages': 'Messages',
  '/contacts': 'Contacts',
}

const Header = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(state => state.menu.isOpen)

  return (
    <div className='w-full h-14 flex-none flex justify-center items-center text-center bg-black text-white'>
        <div
          className={
            'absolute top-2.5 right-2 w-10 h-9 px-1 py-1.5 flex flex-col justify-between items-center rounded transition duration-300 '
            + (isOpen ? '-rotate-180' : '')
          }
          onClick={() => dispatch(toggleMenu())}
        >
            <div className='w-full h-1 bg-white rounded' />
            <div className='w-full h-1 bg-white rounded' />
            <div className='w-full h-1 bg-white rounded' />
        </div>
        <h1 className='text-2xl'>{locationNames[location.pathname] || 'Announcements'}</h1>
    </div>
  )
}

export default Header