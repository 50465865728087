import React from 'react'
import MenuItem from './MenuItem'
import { ReactComponent as MegaphoneIcon } from '../../icons/megaphone.svg'
import { ReactComponent as ContactsIcon } from '../../icons/contacts.svg'
// import { ReactComponent as MessageIcon } from '../../icons/message.svg'
import { ReactComponent as SignOutIcon } from '../../icons/sign-out.svg'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { closeMenu } from '../../slices/menu'
import { clearApiKey } from '../../slices/auth'

const Menu = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(state => state.menu.isOpen)

  return (
    <div
      className={
        'absolute top-0 left-0 w-full h-full '
        + (!isOpen ? 'translate-x-full delay-500' : '')
      }
      onClick={() => dispatch(closeMenu())}
    >
      <div
        className={
          'absolute top-0 right-0 w-full md:w-96 h-full px-4 py-2 flex flex-col items-center bg-black/[.85] text-white transition duration-500 '
          + (isOpen ? 'opacity-100' : 'opacity-10 translate-x-full')
        }
      >
        <MenuItem
          title='Announcements'
          icon={<MegaphoneIcon />}
          onClick={() => navigate('/')}
        />
        <MenuItem
          title='Contacts'
          icon={<ContactsIcon />}
          onClick={() => navigate('/contacts')}
        />
        {/* <MenuItem
          title='Messages'
          icon={<MessageIcon />}
          onClick={() => navigate('/messages')}
        /> */}
        <MenuItem
          title='Sign Out'
          icon={<SignOutIcon />}
          onClick={() => {
            dispatch(clearApiKey())
            navigate('/signin')
          }}
        />
      </div>
    </div>
  )
}

export default Menu