import React, { useCallback, useEffect, useRef, useState } from 'react'
import Button from '../../components/form/Button'
import ConfirmButton from '../../components/form/ConfirmButton'
import BinaryDropdown from '../../components/form/BinaryDropdown'
import Loading from '../../components/Loading'
import { ReactComponent as RefreshIcon } from '../../icons/refresh.svg'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setCurrentAnnouncement, prependAnnouncement, setAnnouncementOffset, setMoreAnnouncementsAvailable, appendAnnouncements, setAnnouncementsLoading, setShouldSendToAll, setSelectedContacts, setSending } from '../../slices/announcements'
import AnnouncementT from '../../types/Announcement'
import Announcement from './Announcement'
import ContactSelector from './ContactSelector'
import formatNumber from '../../util/formatNumber'
import { getMessageInfo } from '../../util/getMessageInfo'
import { setContacts, setContactsLoading } from '../../slices/contacts'
import Contact from '../../types/Contact'

const Announcements = () => {
  const dispatch = useAppDispatch()

  const [error, setError] = useState('')
  const [sendError, setSendError] = useState('')

  const {
    initialLoaded,
    loading: announcementsLoading,
    sending,
    announcements,
    currentAnnouncement,
    moreAnnouncementsAvailable,
    announcementOffset,
    shouldSendToAll,
    selectedContacts,
  } = useAppSelector(state => state.announcements)
  const { apiKey } = useAppSelector(state => state.auth)

  const {
    initialLoaded: initialContactsLoaded,
    subscribedContactsAmount,
  } = useAppSelector(state => state.contacts)

  const [{ isGsm, characterCount, messageParts, maxLength }, setMessageInfo] =
    useState(getMessageInfo(currentAnnouncement))
  
  const announcementTextAreaRef = useRef<HTMLTextAreaElement | null>(null)

  const resizeTextarea = useCallback(() => {
    requestAnimationFrame(() => {
      const textarea = announcementTextAreaRef.current
      if (textarea !== null) {
        textarea.style.height = '2.625rem'
        textarea.style.height = `calc(min(${textarea.scrollHeight + 2}px, 24rem, 100vh - 14rem))`
      }
    })
  }, [])

  const fetchAnnouncements = useCallback(async () => {
    try {
      dispatch(setAnnouncementsLoading(true))
      setError('')

      const body = JSON.stringify({ limit: 10, offset: announcementOffset })

      const res = await fetch('https://announcements.gospelga.com/api/announcements', {
        method: 'POST',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json',
        },
        body,
      })

      if (!res.ok) throw new Error('Failed to get announcements.');

      const data: {
        totalAnnouncements: number
        announcements: AnnouncementT[]
      } = await res.json()

      if ((announcements.length + data.announcements.length) < data.totalAnnouncements)
        dispatch(setAnnouncementOffset(announcementOffset + data.announcements.length))
      else
        dispatch(setMoreAnnouncementsAvailable(false))

      dispatch(appendAnnouncements(data.announcements))
    } catch(e: any) {
      setError(e.message ? `Error: ${e.message}` : 'An unknown error occured.')
    } finally {
      dispatch(setAnnouncementsLoading(false))
    }
  }, [announcements.length, announcementOffset, apiKey, dispatch])

  const [vonageBalanceLoading, setVonageBalanceLoading] = useState(true)
  const [vonageBalance, setVonageBalance] = useState<string | undefined>(undefined)
  const getVonageBalance = useCallback(async() => {
    try {
      setVonageBalanceLoading(true)
      setError('')

      const res = await fetch(
        'https://announcements.gospelga.com/api/vonage/balance', {
        headers: { 'x-api-key': apiKey, },
      })

      if (!res.ok) throw new Error('Invalid server response.');

      const { balance }: { balance: number } = await res.json()

      let balanceStr = '' + balance
      if (balance === Math.round(balance)) balanceStr += '.00'
      else if (balance === (Math.round(balance * 10) / 10)) balanceStr += '0'
      
      setVonageBalance(balanceStr)
    } catch(e: any) {
      setSendError(
        e.message
        ? `Error getting Vonage balance: ${e.message}`
        : 'An unknown error occured while getting Vonage balance'
      )
    } finally {
      setVonageBalanceLoading(false)
    }
  }, [apiKey])

  const [smsPricing, setSmsPricing] =
    useState<{ min: number, max: number } | undefined>(undefined)
  const getSmsPricing = useCallback(async() => {
    try {
      const res = await fetch(
        'https://announcements.gospelga.com/api/vonage/sms-pricing', {
        headers: { 'x-api-key': apiKey, },
      })

      if (!res.ok) {
        try {
          console.log(await res.json())
        } finally {
          throw new Error('Invalid server response.')
        }
      }

      const data: {
        defaultPrice: number,
        maxPrice: number,
        minPrice: number,
      } = await res.json()

      setSmsPricing({
        min: data.minPrice,
        max: data.maxPrice,
      })
    } finally {}
  }, [apiKey])

  useEffect(() => {
    setMessageInfo(getMessageInfo(currentAnnouncement))
  }, [currentAnnouncement])

  useEffect(() => {
    if (!initialLoaded) {
      fetchAnnouncements()
    }
    getVonageBalance()
    setTimeout(getSmsPricing, 1000)
    if (!initialContactsLoaded) {
      const fetchContacts = async () => {
        try {
          dispatch(setContactsLoading(true))
    
          const res = await fetch('https://announcements.gospelga.com/api/contacts', {
            method: 'POST',
            headers: { 'x-api-key': apiKey },
          })
    
          if (!res.ok) throw new Error('Failed to get contacts.');
    
          const fetchedContacts = (await res.json() as Contact[]).sort(
            (a, b) => (
              (a.name && b.name)
              ? a.name.localeCompare(b.name)
              : a.name && !b.name
              ? -1
              : !a.name && b.name
              ? 1
              : a.phoneNumber.localeCompare(b.phoneNumber)
            )
          )
    
          dispatch(setContacts(fetchedContacts))
          dispatch(setContactsLoading(false))
        } finally {}
      }
      fetchContacts()
    }
    resizeTextarea()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(resizeTextarea, [currentAnnouncement])

  return (
    <div className='w-full h-full md:w-[40rem] flex flex-col'>
      <div className="w-full h-fit mb-5 flex flex-col">
        <div className="w-full flex justify-end items-center text-lg mb-3">
          <div className="mr-2">Vonage balance:</div>
          {vonageBalanceLoading ? (
            <Loading addClassName='w-5 h-5'/>
          ) : (
            <>
              <div className='mr-2 font-bold'>
                {vonageBalance !== undefined ? `€${vonageBalance}` : 'Unknown'}
              </div>
              <Button
                onClick={getVonageBalance}
                addClassName='w-4 h-4 px-0 py-0 text-black bg-transparent'
              >
                <RefreshIcon className='w-full h-full'/>
              </Button>
            </>
          )}
        </div>
        <div className="flex justify-between items-center mb-1">
          <label className='flex-none'>Announcement</label>
          {isGsm !== undefined && messageParts !== undefined && maxLength !== undefined && (
            <div className="flex justify-end w-full relative">
              <div className='absolute right-16'>{messageParts} {isGsm ? 'GSM-7' : 'UCS-2'} message{messageParts > 1 && 's'}</div>
              {messageParts !== undefined && maxLength !== undefined && (
                <div>{characterCount}/{maxLength}</div>
              )}
            </div>
          )}
        </div>
        <textarea
          ref={announcementTextAreaRef}
          className='w-full h-[2.625rem] p-2 mb-3 rounded border border-gray-300 outline-none focus:border-black disabled:bg-gray-100'
          style={{ resize: 'none' }}
          value={currentAnnouncement}
          onChange={e => dispatch(setCurrentAnnouncement(e.target.value))}
          disabled={announcementsLoading || sending}
        />
        <BinaryDropdown
          label='Send to'
          labelTrue='Everyone subscribed'
          labelFalse='Specific people'
          value={shouldSendToAll}
          setValue={value => dispatch(setShouldSendToAll(!!value))}
          addClassName={shouldSendToAll ? 'mb-4' : 'mb-3'}
          disabled={announcementsLoading || sending}
        />
        {!shouldSendToAll && (
          <ContactSelector
            label='Recipients'
            selectedContacts={selectedContacts}
            setSelectedContacts={(contacts) => dispatch(setSelectedContacts(contacts))}
            addClassName='mb-4'
            disabled={announcementsLoading || sending}
          />
        )}
        <ConfirmButton
          addClassName='w-full'
          disabled={announcementsLoading || sending || !currentAnnouncement.trim()}
          confirmTitle="Final Review"
          confirmMessage={
            <>
              <div className='p-2 mb-3 w-full rounded border border-gray-300 bg-gray-100 whitespace-pre-wrap overflow-y-auto'>
                {currentAnnouncement.trim()}
              </div>
              <div className="mb-1 text-center max-h-24 overflow-y-auto p-2 ">
                To: {shouldSendToAll ? 'Everyone subscribed' : (
                  selectedContacts.reduce((all, contact, index) => {
                    const nameOrNumber = contact.name || formatNumber(contact.phoneNumber)
                    return index === 0
                    ? nameOrNumber
                    : (all + ', ' + nameOrNumber)
                  }, '') || 'Nobody'
                )}
              </div>
              {messageParts && (
                smsPricing ? (
                  <div className="flex-none mb-4 text-center font-bold text-center">
                    Estimated cost: €{
                      Math.round(smsPricing.min * messageParts * (
                        (
                          shouldSendToAll
                          ? subscribedContactsAmount
                          : selectedContacts.length
                          ) * 100
                        )
                      ) / 100
                    } - €{
                      Math.round(smsPricing.max * messageParts * (
                        (
                          shouldSendToAll
                          ? subscribedContactsAmount
                          : selectedContacts.length
                          ) * 100
                        )
                      ) / 100
                    }
                  </div>
                ) : (
                  <div className="flex-none mb-3 text-center font-bold text-center">
                    Estimated cost: Loading...
                  </div>
                )
              )}
            </>
          }
          confirmButtonText="Send"
          cancelButtonText="Cancel"
          onClick={async () => {
            try {
              if (!shouldSendToAll && !selectedContacts.length)
                return setSendError('Please choose at least one recipient')
              dispatch(setSending(true))
              if (sendError) setSendError('');
              const res = await fetch('https://announcements.gospelga.com/api/send', {
                method: 'POST',
                headers: { 
                  'x-api-key': apiKey,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  text: currentAnnouncement.trim(),
                  ...(!shouldSendToAll && { recipients: selectedContacts })
                })
              })
              if (!res.ok) {
                let data: any = {};
                try {
                  data = await res.json()
                  if (data.announcement) {
                    dispatch(setAnnouncementOffset(announcementOffset + 1))
                    dispatch(prependAnnouncement(data))
                  }
                } catch(e) {
                  throw new Error('An unknown error occured.')
                }
                if (data.error) throw new Error(data.error);
                else throw new Error('An unknown error occured.')
              }
              const data = await res.json()
              dispatch(setAnnouncementOffset(announcementOffset + 1))
              dispatch(prependAnnouncement(data))
              dispatch(setCurrentAnnouncement(''))
            } catch(e: any) {
              setSendError(e.message)
            } finally {
              dispatch(setSending(false))
            }
          }}
        >
          Send announcement
        </ConfirmButton>
      </div>
      {sending ? (
        <div className='w-full flex justify-center items-center'>
          <Loading addClassName='w-5 h-5' />
          <div className="font-bold text-lg ml-2">Sending...</div>
        </div>
      ) : sendError && (
        <div className='w-full text-center text-lg text-red-600'>
          {sendError}
        </div>
      )}
      <h2 className='text-2xl mt-3 text-center'>Announcement History</h2>
      <div className='w-full flex flex-col py-2'>
        {announcements.length ? announcements.map(announcement => (
          <Announcement key={announcement.id} announcement={announcement} />
        )) : !announcementsLoading && (
          <div className='text-lg text-center text-gray-500 italic'>None.</div>
        )}
        {error ? (
          <div className='w-full mt-2 text-center text-lg text-red-600'>
            {error}
          </div>
        ) : announcementsLoading ? (
          <div className='w-full mt-4 flex justify-center items-center'>
            <Loading addClassName='w-8 h-8' />
          </div>
        ) : moreAnnouncementsAvailable && (
          <Button
            addClassName='my-3'
            onClick={fetchAnnouncements}
          >
            Load more
          </Button>
        )}
      </div>
    </div>
  )
}

export default Announcements