import React, { forwardRef } from 'react'

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  type?: string
  setValue?(value: string): void
  label?: string
  addClassName?: string
}

const Input = forwardRef<HTMLInputElement, Props>(({
  type = 'text',
  value,
  setValue,
  onChange,
  label,
  disabled,
  addClassName,
  ...props
}, ref) => {
  return (
    <div className={'w-full flex flex-col text-left' + (addClassName ? ' ' + addClassName : '')}>
      <label className='mb-1 select-none'>{label}</label>
      <input
          ref={ref}
          type={type}
          className={
            'w-full h-10 p-2 rounded bg-white border border-gray-300 outline-none focus:border-black'
            + (disabled ? " bg-gray-100" : "")
          }
          value={value}
          disabled={disabled}
          onChange={onChange ? onChange : setValue ? e => setValue(e.target.value) : undefined}
          {...props}
      />
    </div>
  )
})

export default Input
