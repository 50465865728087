import React, { useRef, useState } from 'react'
import BinaryDropdown from '../../components/form/BinaryDropdown';
import Button from '../../components/form/Button';
import Input from '../../components/form/Input';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { updateContact } from '../../slices/contacts';
import Contact from '../../types/Contact';
import Member from '../../types/Member';
import formatNumber from '../../util/formatNumber';

interface Props {
  onDone: (addedContact: Contact) => void
  onCancel: () => void
}

const AddContact = ({
  onDone,
  onCancel,
}: Props) => {

  const dispatch = useAppDispatch()
  const { apiKey } = useAppSelector(state => state.auth)

  const phoneNumberInputRef = useRef<HTMLInputElement | null>(null)
  const addPhoneNumberBtnRef = useRef<HTMLButtonElement | null>(null)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isSubscribed, setIsSubscribed] = useState<boolean | null>(true)

  const [memberFoundInCrm, setMemberFoundInCrm] = useState<Member | null>(null)

  return (
    <div className="z-20 bg-black bg-opacity-40 fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center">
      <div className="w-11/12 sm:w-[30rem] max-h-[94%] drop-shadow-md p-6 bg-white rounded flex flex-col items-center">
        <h1 className='text-3xl mb-5 text-center'>Add Contact</h1>
        <div className='w-full flex flex-col items-center overflow-visible'>
          {memberFoundInCrm === null ? (
            <>
              <Input
                label='Name (optional)'
                value={name}
                setValue={setName}
                onKeyDown={e => {
                  if (e.key === 'Enter') phoneNumberInputRef.current?.focus()
                }}
                addClassName='mb-3'
                disabled={loading}
              />
              <Input
                ref={phoneNumberInputRef}
                label='Phone Number'
                value={phoneNumber}
                setValue={setPhoneNumber}
                onKeyDown={e => {
                  if (e.key === 'Enter') addPhoneNumberBtnRef.current?.click()
                }}
                addClassName='mb-3'
                disabled={loading}
              />
              <BinaryDropdown
                label="Subscribed to announcements"
                value={isSubscribed}
                setValue={setIsSubscribed}
                disabled={loading}
                addClassName='w-full mb-2'
                includeNull={true}
                labelNull='Unknown'
              />
              {error && (
                <div className='w-full text-center text-lg text-red-600'>
                  {error}
                </div>
              )}
              <Button
                ref={addPhoneNumberBtnRef}
                addClassName="w-full mt-3"
                disabled={loading}
                onClick={async () => {
                  const cleanName = name.trim()
                  const cleanPhoneNumber = phoneNumber.trim()
                  if (!cleanPhoneNumber) {
                    return setError('Please enter a phone number');
                  }
                  try {
                    setLoading(true)
                    if (error) setError('');
                    console.log(JSON.stringify({
                      isSubscribed,
                      number: phoneNumber.trim(),
                      ...(cleanName && { name: cleanName }),
                    }))
                    const res = await fetch('https://announcements.gospelga.com/api/contact', {
                      method: 'POST',
                      headers: { 
                        'x-api-key': apiKey,
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({
                        isSubscribed,
                        number: phoneNumber.trim(),
                        ...(cleanName && { name: cleanName }),
                      })
                    })
                    if (!res.ok) {
                      let data: any = {};
                      try {
                        data = await res.json()
                        if (
                          res.status === 422
                          && data
                          && data.id
                          && data.name
                        ) {
                          setMemberFoundInCrm(data)
                          return setLoading(false)
                        }
                      } catch(e) {
                        throw new Error('An unknown error occured.')
                      }
                      if (data.error) throw new Error(data.error);
                      else throw new Error('An unknown error occured.')
                    }
                    const data = await res.json()
                    onDone(data)
                  } catch(e: any) {
                    setError(e.message)
                    setLoading(false)
                  }
                }}
              >
                Add contact
              </Button>
            </>
          ) : (
            <>
              <div className='mb-3 text-lg text-center'>
                The phone number you entered matched an existing record for {memberFoundInCrm.name}.
                Do you want to update the record or add {formatNumber(memberFoundInCrm.cellPhone)} as a new contact?
              </div>
              {error && (
                <div className='w-full text-center text-lg text-red-600'>
                  {error}
                </div>
              )}
              <Button
                addClassName="w-full mt-3"
                onClick={async () => {
                  try {
                    setLoading(true)
                    if (error) setError('');
                    const res = await fetch(`https://announcements.gospelga.com/api/crm-contact/${memberFoundInCrm.id}`, {
                      method: 'POST',
                      headers: { 
                        'x-api-key': apiKey,
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({ isSubscribed })
                    })
                    if (!res.ok) {
                      let data: any = {};
                      try {
                        data = await res.json()
                      } catch(e) {
                        throw new Error('An unknown error occured.')
                      }
                      if (data.error) throw new Error(data.error);
                      else throw new Error('An unknown error occured.')
                    }
                    const data = await res.json()
                    dispatch(updateContact(data))
                    onCancel()
                  } catch(e: any) {
                    setError(e.message)
                    setLoading(false)
                  }
                }}
                disabled={loading}
              >
                Update record
              </Button>
              <Button
                addClassName="w-full mt-3"
                onClick={async () => {
                  try {
                    setLoading(true)
                    const cleanName = name.trim()
                    if (error) setError('');
                    const res = await fetch('https://announcements.gospelga.com/api/contact', {
                      method: 'POST',
                      headers: { 
                        'x-api-key': apiKey,
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({
                        isSubscribed,
                        number: phoneNumber.trim(),
                        ...(cleanName && { name: cleanName }),
                        skipCrmCheck: true,
                      })
                    })
                    if (!res.ok) {
                      let data: any = {};
                      try {
                        data = await res.json()
                      } catch(e) {
                        throw new Error('An unknown error occured.')
                      }
                      if (data.error) throw new Error(data.error);
                      else throw new Error('An unknown error occured.')
                    }
                    const data = await res.json()
                    onDone(data)
                  } catch(e: any) {
                    setError(e.message)
                    setLoading(false)
                  }
                }}
                disabled={loading}
              >
                Create new contact
              </Button>
            </>
          )}
          <Button
            addClassName="w-full mt-3 bg-red-600"
            onClick={onCancel}
            disabled={loading}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AddContact