import React, { useCallback, useEffect, useRef, useState } from 'react'
import Button from '../../components/form/Button'
import Loading from '../../components/Loading'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { ReactComponent as PlusIcon } from '../../icons/plus.svg'
import { ReactComponent as FilterIcon } from '../../icons/filter.svg'
import { ReactComponent as DownArrowIcon } from '../../icons/down-arrow.svg'
import { addContact, removeContact, setContacts, setContactsLoading, updateContact } from '../../slices/contacts'
import Contact from '../../types/Contact'
import AddContact from './AddContact'
import ContactItem from './ContactItem'
import EditContact from './EditContact'
import BinaryDropdown from '../../components/form/BinaryDropdown'
import Input from '../../components/form/Input'
import Dropdown from '../../components/form/Dropdown'

const Contacts = () => {
  const dispatch = useAppDispatch()

  const [error, setError] = useState('')

  const [isAddContactShowing, setIsAddContactShowing] = useState(false);
  const [contactBeingEdited, setContactBeingEdited] = useState<Contact | null>(null)

  const {
    initialLoaded,
    loading: contactsLoading,
    contacts,
  } = useAppSelector(state => state.contacts)

  const [loading, setLoading] = useState(contactsLoading)

  const [visibleContacts, setVisibleContacts] = useState(contacts)

  const [showFilters, setShowFilters] = useState(false)
  const [isSubscribedFilter, setIsSubscribedFilter] =
    useState<boolean | null | undefined>(undefined)
  const [isInCrmFilter, setIsInCrmFilter] = useState<boolean | null>(null)

  const [searchString, setSearchString] = useState<string>('')
  const [searchFilter, setSearchFilter] = useState<string>('')

  const timeoutId = useRef<number | undefined>(undefined)
  const pendingSearch = useRef<string | undefined>(undefined)

  const exportAsCsv = useCallback(() => {
    const headers = 'id,is_in_crm,name,phone_number,is_subscribed\n'
    const rows = visibleContacts.map(
      ({ id, isInCrm, name, phoneNumber, isSubscribed}) => (
        `${id},${isInCrm},${name},${phoneNumber},${isSubscribed}`
      )
    ).join('\n')
    const uri = encodeURI('data:text/csv;charset=utf-8,' + headers + rows)
    const link = document.createElement('a');
    link.download = 'contacts.csv';
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, [visibleContacts])

  const throttledSetSearchFilter = useCallback((value: string) => {
    if (!value) {
      clearTimeout(timeoutId.current)
      timeoutId.current = undefined
      return setSearchFilter('')
    }
    if (!timeoutId.current) {
      setSearchFilter(value)
      timeoutId.current = window.setTimeout(() => {
        if (pendingSearch.current) {
          setSearchFilter(pendingSearch.current)
          pendingSearch.current = undefined
        }
        timeoutId.current = undefined
      }, 500)
    } else {
      pendingSearch.current = value
    }
  }, [])
  
  const { apiKey } = useAppSelector(state => state.auth)

  useEffect(() => {
    const trimmedSearch = searchFilter.trim()
    const phoneSearch = trimmedSearch.replace(/[^0-9]+/g, '')
    const nameSearch = trimmedSearch.replace(/[^a-zA-Z\s]+/g, '').trim().replace(/\s+/g, ' ').toLowerCase()

    setVisibleContacts(contacts.filter(contact => {
      if (
        (isSubscribedFilter !== undefined && contact.isSubscribed !== isSubscribedFilter)
        || (isInCrmFilter !== null && contact.isInCrm !== isInCrmFilter)
      ) return false

      if (trimmedSearch) {
        if (phoneSearch && !contact.phoneNumber.includes(phoneSearch)) return false
        if (nameSearch) {
          if (!contact.name) return false
          const lowercaseName = contact.name.toLowerCase()
          if (nameSearch.includes(' ')) {
            const searchParts = nameSearch.split(' ')
            const nameParts = lowercaseName.split(' ')
            for (const searchPart of searchParts) {
              let searchPartFound = false
              for (const namePart of nameParts)
                if (namePart.includes(searchPart)) searchPartFound = true
              if (!searchPartFound) return false
            }
          } else {
            if (!lowercaseName.includes(nameSearch)) return false
          }
        }
      }

      return true
    }))
  }, [contacts, isSubscribedFilter, isInCrmFilter, searchFilter])

  useEffect(() => setLoading(contactsLoading), [contactsLoading])

  useEffect(() => {
    if (!initialLoaded) {
      const fetchContacts = async () => {
        try {
          dispatch(setContactsLoading(true))
          if (error) setError('')
    
          const res = await fetch('https://announcements.gospelga.com/api/contacts', {
            method: 'POST',
            headers: { 'x-api-key': apiKey },
          })
    
          if (!res.ok) throw new Error('Failed to get saved numbers.');
    
          const fetchedContacts = (await res.json() as Contact[]).sort(
            (a, b) => (
              (a.name && b.name)
              ? a.name.localeCompare(b.name)
              : a.name && !b.name
              ? -1
              : !a.name && b.name
              ? 1
              : a.phoneNumber.localeCompare(b.phoneNumber)
            )
          )
    
          setVisibleContacts(fetchedContacts)
          dispatch(setContacts(fetchedContacts))
          dispatch(setContactsLoading(false))
        } catch(e: any) {
          setError(e.message ? `Error: ${e.message}` : 'An unknown error occured.')
        }
      }
      fetchContacts()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='w-full h-full md:w-[40rem] flex flex-col'>
      <Button
        onClick={() => setIsAddContactShowing(true)}
      >
        <div className='w-full flex justify-center items-center'>
          <PlusIcon className='w-5 h-5 mr-3' />
          <div>Add contact</div>
        </div>
      </Button>
      <div className="w-full flex flex-col mt-4">
        <Button
          onClick={() => setShowFilters(!showFilters)}
          addClassName={showFilters ? 'rounded-b-none' : ''}
        >
          <div className='relative w-full flex justify-center items-center'>
            <FilterIcon className='w-5 h-5 mr-2' />
            <div>Filters</div>
            <DownArrowIcon
              className={
                'absolute right-1.5 w-5 h-5'
                + (showFilters ? ' rotate-180' : '')
              }
            />
          </div>
        </Button>
        <div
          className={
            "flex flex-col border border-black rounded-b p-4 pb-5"
            + (showFilters ? '' : ' hidden')
          }
        >
          <div className='w-full text-center text-xl font-bold mb-3'>
            Results: {visibleContacts.length}
          </div>
          <div className="w-full flex flex-col mb-4">
            <div className="mb-1">Is stored in CRM</div>
            <BinaryDropdown
              addClassName='w-full'
              value={isInCrmFilter}
              setValue={setIsInCrmFilter}
              includeNull={true}
              labelNull='Any'
            />
          </div>
          <Dropdown
            label='Should receive announcements'
            options={[
              { name: 'Yes', value: 'true' },
              { name: 'No', value: 'false' },
              { name: 'Unknown', value: 'null' },
              { name: 'Any', value: 'undefined' },
            ]}
            value={(
              isSubscribedFilter === true ? 'true'
              : isSubscribedFilter === false ? 'false'
              : isSubscribedFilter === null ? 'null'
              : 'undefined'
            )}
            setValue={(value) => {
              if (value === 'true') setIsSubscribedFilter(true)
              else if (value === 'false') setIsSubscribedFilter(false)
              else if (value === 'null') setIsSubscribedFilter(null)
              else setIsSubscribedFilter(undefined)
            }}
            addClassName='w-full mb-4'
            disabled={loading}
          />
          <Input
            label='Search'
            value={searchString}
            setValue={value => {
              setSearchString(value)
              throttledSetSearchFilter(value)
            }}
            addClassName='mb-4'
            disabled={loading}
          />
          <Button
            onClick={exportAsCsv}
            disabled={loading || !visibleContacts.length}
          >
            Export filtered contacts as CSV
          </Button>
        </div>
      </div>
      <div className='w-full flex flex-col mt-4'>
        {error ? (
          <div className='w-full text-center text-lg text-red-600'>
            {error}
          </div>
        ) : loading ? (
          <div className='w-full flex justify-center items-center mt-2'>
            <Loading addClassName='w-8 h-8' />
          </div>
        ) : visibleContacts.length ? visibleContacts.map(contact => (
          <ContactItem
            key={`${contact.isInCrm ? 'crm' : 'contact'}-${contact.id}`}
            contact={contact}
            setContactBeingEdited={setContactBeingEdited}
          />
        )) : !loading && (
          <div className='text-lg text-center text-gray-500 italic'>No contacts found.</div>
        )}
      </div>
      {isAddContactShowing && (
        <AddContact
          onDone={addedContact => {
            dispatch(addContact(addedContact))
            setIsAddContactShowing(false)
          }}
          onCancel={() => setIsAddContactShowing(false)}
        />
      )}
      {contactBeingEdited && (
        <EditContact
          initialContact={contactBeingEdited}
          onDone={updatedContact => {
            dispatch(updateContact(updatedContact))
            setContactBeingEdited(null)
          }}
          onCancel={() => setContactBeingEdited(null)}
          onDelete={() => {
            dispatch(removeContact(contactBeingEdited))
            setContactBeingEdited(null)
          }}
        />
      )}
    </div>
  )
}

export default Contacts